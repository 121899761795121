import React from 'react'
import './AcceuilPageTwo.css'
function AcceuilPageTwo() {
  return (
    <div className='AcceuilPageTwo'>
        <video src='/videoPresentation.mp4' autoPlay muted controls id='videoPresentationAcceuil'/>
    </div>
  )
}

export default AcceuilPageTwo