import React from 'react'
import '../RealisationOne/Realisationone.css'
import Carousel from 'react-bootstrap/Carousel';

function RealisationTwo() {
    return (
        <Carousel id='idCarouselOneRealistaion'>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant21.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de cet espace avant l'intervention de HA SERVICES BÂTIMENT. Ce projet nécessite une rénovation complète des murs, sols et menuiseries, avec des finitions à revoir pour un résultat de qualité.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant22.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de cet espace avant l'intervention de HA SERVICES BÂTIMENT. Ce projet nécessite une rénovation complète des murs, sols et menuiseries, avec des finitions à revoir pour un résultat de qualité.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        
    
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/ent21.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein cœur de la transformation, HA SERVICES BÂTIMENT s'active pour donner vie à ce projet. Les travaux de plâtrerie, menuiserie et carrelage avancent avec précision, tandis que la peinture et le ravalement de façade commencent à révéler le futur aspect de l'espace. Chaque étape est réalisée avec soin pour garantir un résultat final à la hauteur des attentes.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/ent22.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein cœur de la transformation, HA SERVICES BÂTIMENT s'active pour donner vie à ce projet. Les travaux de plâtrerie, menuiserie et carrelage avancent avec précision, tandis que la peinture et le ravalement de façade commencent à révéler le futur aspect de l'espace. Chaque étape est réalisée avec soin pour garantir un résultat final à la hauteur des attentes.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/ent23.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein cœur de la transformation, HA SERVICES BÂTIMENT s'active pour donner vie à ce projet. Les travaux de plâtrerie, menuiserie et carrelage avancent avec précision, tandis que la peinture et le ravalement de façade commencent à révéler le futur aspect de l'espace. Chaque étape est réalisée avec soin pour garantir un résultat final à la hauteur des attentes.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/ent24.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein cœur de la transformation, HA SERVICES BÂTIMENT s'active pour donner vie à ce projet. Les travaux de plâtrerie, menuiserie et carrelage avancent avec précision, tandis que la peinture et le ravalement de façade commencent à révéler le futur aspect de l'espace. Chaque étape est réalisée avec soin pour garantir un résultat final à la hauteur des attentes.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr25.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein cœur de la transformation, HA SERVICES BÂTIMENT s'active pour donner vie à ce projet. Les travaux de plâtrerie, menuiserie et carrelage avancent avec précision, tandis que la peinture et le ravalement de façade commencent à révéler le futur aspect de l'espace. Chaque étape est réalisée avec soin pour garantir un résultat final à la hauteur des attentes.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr21.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr22.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhoto'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr23.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
       
       
      </Carousel>
      )
}

export default RealisationTwo