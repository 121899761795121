import React from 'react'
import './RealisationFive.css'
import Carousel from 'react-bootstrap/Carousel';

function RealisationFive
() {
    return (
        <Carousel id='idCarouselOneRealistaion'>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant51.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de la pièce avant l'intervention de HA SERVICES BÂTIMENT. Les murs sont bruts et non préparés, sans revêtement, et le sol est en attente de la pose de parquet. Cette rénovation nécessite des travaux complets de placo, traitement des bandes, peinture et installation du parquet pour donner un aspect neuf et moderne.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant52.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de la pièce avant l'intervention de HA SERVICES BÂTIMENT. Les murs sont bruts et non préparés, sans revêtement, et le sol est en attente de la pose de parquet. Cette rénovation nécessite des travaux complets de placo, traitement des bandes, peinture et installation du parquet pour donner un aspect neuf et moderne.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant53.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de la pièce avant l'intervention de HA SERVICES BÂTIMENT. Les murs sont bruts et non préparés, sans revêtement, et le sol est en attente de la pose de parquet. Cette rénovation nécessite des travaux complets de placo, traitement des bandes, peinture et installation du parquet pour donner un aspect neuf et moderne.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant54.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de la pièce avant l'intervention de HA SERVICES BÂTIMENT. Les murs sont bruts et non préparés, sans revêtement, et le sol est en attente de la pose de parquet. Cette rénovation nécessite des travaux complets de placo, traitement des bandes, peinture et installation du parquet pour donner un aspect neuf et moderne.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant55.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici l'état initial de la pièce avant l'intervention de HA SERVICES BÂTIMENT. Les murs sont bruts et non préparés, sans revêtement, et le sol est en attente de la pose de parquet. Cette rénovation nécessite des travaux complets de placo, traitement des bandes, peinture et installation du parquet pour donner un aspect neuf et moderne.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr51.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein processus de transformation, nos équipes posent les plaques de placo avec précision et soignent les bandes pour une finition lisse et sans défaut. La peinture est en cours d'application pour apporter une nouvelle couleur à l’espace, tandis que la pose du parquet commence, assurant un sol élégant et résistant. Chaque étape est réalisée avec minutie pour garantir un résultat impeccable.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr52.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein processus de transformation, nos équipes posent les plaques de placo avec précision et soignent les bandes pour une finition lisse et sans défaut. La peinture est en cours d'application pour apporter une nouvelle couleur à l’espace, tandis que la pose du parquet commence, assurant un sol élégant et résistant. Chaque étape est réalisée avec minutie pour garantir un résultat impeccable.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr53.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein processus de transformation, nos équipes posent les plaques de placo avec précision et soignent les bandes pour une finition lisse et sans défaut. La peinture est en cours d'application pour apporter une nouvelle couleur à l’espace, tandis que la pose du parquet commence, assurant un sol élégant et résistant. Chaque étape est réalisée avec minutie pour garantir un résultat impeccable.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr54.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein processus de transformation, nos équipes posent les plaques de placo avec précision et soignent les bandes pour une finition lisse et sans défaut. La peinture est en cours d'application pour apporter une nouvelle couleur à l’espace, tandis que la pose du parquet commence, assurant un sol élégant et résistant. Chaque étape est réalisée avec minutie pour garantir un résultat impeccable.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr55.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En plein processus de transformation, nos équipes posent les plaques de placo avec précision et soignent les bandes pour une finition lisse et sans défaut. La peinture est en cours d'application pour apporter une nouvelle couleur à l’espace, tandis que la pose du parquet commence, assurant un sol élégant et résistant. Chaque étape est réalisée avec minutie pour garantir un résultat impeccable.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr54.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la pièce complètement rénovée par HA SERVICES BÂTIMENT. Les murs sont parfaitement lisses, les bandes et la peinture appliquées avec soin, apportant une finition uniforme. Le parquet, posé avec précision, offre un sol chaleureux et moderne. L'ensemble respire la qualité et la durabilité, transformant l'espace en un lieu accueillant et fonctionnel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr53.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la pièce complètement rénovée par HA SERVICES BÂTIMENT. Les murs sont parfaitement lisses, les bandes et la peinture appliquées avec soin, apportant une finition uniforme. Le parquet, posé avec précision, offre un sol chaleureux et moderne. L'ensemble respire la qualité et la durabilité, transformant l'espace en un lieu accueillant et fonctionnel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr52.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la pièce complètement rénovée par HA SERVICES BÂTIMENT. Les murs sont parfaitement lisses, les bandes et la peinture appliquées avec soin, apportant une finition uniforme. Le parquet, posé avec précision, offre un sol chaleureux et moderne. L'ensemble respire la qualité et la durabilité, transformant l'espace en un lieu accueillant et fonctionnel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
       
    
        <Carousel.Item id='blocPhotoFive'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr51.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la pièce complètement rénovée par HA SERVICES BÂTIMENT. Les murs sont parfaitement lisses, les bandes et la peinture appliquées avec soin, apportant une finition uniforme. Le parquet, posé avec précision, offre un sol chaleureux et moderne. L'ensemble respire la qualité et la durabilité, transformant l'espace en un lieu accueillant et fonctionnel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
       
       
      </Carousel>
      )
}

export default RealisationFive
