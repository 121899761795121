import React from 'react'
import './RealisationVidContenu.css'
function RealisationVidContenu() {
  return (
    <div className='realisationVidContenu'>
        <video src='/vid1.mp4' muted controls id='videoRealisOne'/>
        <video src='/vid2.mp4' muted controls id='videoRealisOne'/>
        <video src='/vid3.mp4' muted controls id='videoRealisOne'/>
        <video src='/vid4.mp4' muted controls id='videoRealisOne'/>
        <video src='/vid5.mp4' muted controls id='videoRealisOne'/>
    </div>
  )
}

export default RealisationVidContenu