import React from 'react'
import AcceuilPageOne from './AcceuilPageOne/AcceuilPageOne'
import './Acceuil.css'
import AcceuilPageTwo from './AcceuilPageTwo/AcceuilPageTwo'
function Acceuil() {
  return (
    <div>
      <AcceuilPageOne/>
      <AcceuilPageTwo/>
    </div>
  )
}

export default Acceuil