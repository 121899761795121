import React from 'react'
import './RealisationVideo.css'
function RealisationVideo() {
    return (
        <div className='introReal'>
            <h1 id='titredepage'>Nos vidéos de réalisations :</h1>
            <p id='pghrIntro'>
            Parce que certaines transformations méritent d'être vues en action, nous vous invitons à découvrir nos projets à travers ces vidéos. Vous pourrez y suivre nos équipes en plein travail et observer la qualité et le soin que nous apportons à chaque étape de la réalisation. Ces vidéos illustrent non seulement notre savoir-faire, mais aussi notre passion pour créer des espaces qui répondent aux attentes de nos clients.    
    Pour en voir davantage, n'hésitez pas à consulter notre page <a href='https://www.facebook.com/ram16platrerie16'>Facebook</a>  où vous trouverez encore plus de nos réalisations et des projets en cours.
            </p>
        </div>
      )
}

export default RealisationVideo