import React from 'react'
import './RealisationFour.css'
import Carousel from 'react-bootstrap/Carousel';

function RealisationFour() {
    return (
        <Carousel id='idCarouselOneRealistaion'>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant41.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la façade avant l'intervention de HA SERVICES BÂTIMENT. Les signes du temps et des intempéries sont visibles, avec des fissures, une décoloration et une usure générale qui nécessitent une rénovation complète pour redonner tout son éclat au bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant42.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la façade avant l'intervention de HA SERVICES BÂTIMENT. Les signes du temps et des intempéries sont visibles, avec des fissures, une décoloration et une usure générale qui nécessitent une rénovation complète pour redonner tout son éclat au bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant43.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la façade avant l'intervention de HA SERVICES BÂTIMENT. Les signes du temps et des intempéries sont visibles, avec des fissures, une décoloration et une usure générale qui nécessitent une rénovation complète pour redonner tout son éclat au bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant44.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la façade avant l'intervention de HA SERVICES BÂTIMENT. Les signes du temps et des intempéries sont visibles, avec des fissures, une décoloration et une usure générale qui nécessitent une rénovation complète pour redonner tout son éclat au bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant45.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici la façade avant l'intervention de HA SERVICES BÂTIMENT. Les signes du temps et des intempéries sont visibles, avec des fissures, une décoloration et une usure générale qui nécessitent une rénovation complète pour redonner tout son éclat au bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr41.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En pleine phase de ravalement de façade, nos équipes sont à l'œuvre pour réparer les fissures, traiter les surfaces et appliquer des couches protectrices. Le travail de préparation est minutieux, garantissant un rendu final impeccable et une durabilité optimale. La transformation commence déjà à se faire sentir.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr42.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En pleine phase de ravalement de façade, nos équipes sont à l'œuvre pour réparer les fissures, traiter les surfaces et appliquer des couches protectrices. Le travail de préparation est minutieux, garantissant un rendu final impeccable et une durabilité optimale. La transformation commence déjà à se faire sentir.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr43.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>En pleine phase de ravalement de façade, nos équipes sont à l'œuvre pour réparer les fissures, traiter les surfaces et appliquer des couches protectrices. Le travail de préparation est minutieux, garantissant un rendu final impeccable et une durabilité optimale. La transformation commence déjà à se faire sentir.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        
        
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr41.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici le résultat final après l'intervention de HA SERVICES BÂTIMENT. La façade a retrouvé une nouvelle jeunesse avec des finitions parfaites. Grâce à un ravalement de qualité, le bâtiment bénéficie désormais d'une protection renforcée et d'une esthétique moderne qui améliore son attrait visuel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr45.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici le résultat final après l'intervention de HA SERVICES BÂTIMENT. La façade a retrouvé une nouvelle jeunesse avec des finitions parfaites. Grâce à un ravalement de qualité, le bâtiment bénéficie désormais d'une protection renforcée et d'une esthétique moderne qui améliore son attrait visuel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr42.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici le résultat final après l'intervention de HA SERVICES BÂTIMENT. La façade a retrouvé une nouvelle jeunesse avec des finitions parfaites. Grâce à un ravalement de qualité, le bâtiment bénéficie désormais d'une protection renforcée et d'une esthétique moderne qui améliore son attrait visuel.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr43.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici le résultat final après l'intervention de HA SERVICES BÂTIMENT. La façade a retrouvé une nouvelle jeunesse avec des finitions parfaites. Grâce à un ravalement de qualité, le bâtiment bénéficie désormais d'une protection renforcée et d'une esthétique moderne qui améliore son attrait visuel.</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item id='blocPhotoFour'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr44.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Voici le résultat final après l'intervention de HA SERVICES BÂTIMENT. La façade a retrouvé une nouvelle jeunesse avec des finitions parfaites. Grâce à un ravalement de qualité, le bâtiment bénéficie désormais d'une protection renforcée et d'une esthétique moderne qui améliore son attrait visuel.</p>
          </Carousel.Caption>
        </Carousel.Item>
       
      </Carousel>
      )
}

export default RealisationFour