import React from "react";
import "./Contact.css";
import { BsTelephone } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div id="PgContact">
      <div className="headreContact">
        <h1 id="titreDePage">Contactez-nous</h1>
        <h4 id="presDePage">
          Nous sommes disponible du lundi à vendredi de 9h jusqu'à 18h
        </h4>
      </div>
      <div className="BodyContact">
        <div className="CrdContact">
          <BsTelephone className="icnContc" />
          <h2 className="titreCard">Par Téléphone</h2>
          <p className="pghrCard">
            Appelez-Nous au : <br />
            <span id="numContact"> PDG : 0758819885 </span>
            <br />
            <span id="numContact"> Commercial : 0751027802 </span>
            <br />
          </p>
        </div>

        <div className="CrdContact">
          <AiOutlineMail className="icnContc" />
          <h2 className="titreCard">Par Mail</h2>
          <p className="pghrCard">
            Et encore vous pouvez nous envoyer des mail sur : <br />{" "}
            <span id="numContact">haservicesbatiemnt@gmail.com</span>{" "}
          </p>
        </div>
      </div>
      <div className="footerContact">
        <h4 id="phraseFooter">
          Vous pouvez obtenir toutes les informations dont vous avez besoin
          ainsi que les dernières nouveautés sur notre page :
        </h4>
        <div className="iconFooter">
          <Link
            to="https://www.instagram.com/hlel591/profilecard/?igsh=MWp1bHRiMWM0NWFwMw=="
            target="_blank"
          >
            <FaInstagramSquare id="IconInsta" />
          </Link>

          {/* <Link to='https://www.instagram.com/fondation_les_jasmins/?hl=fr'><FaInstagramSquare id='IconInsta'/></Link> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
