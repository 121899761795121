import React from 'react'
import './RealisationIntrod.css'
function RealisationIntrod() {
  return (
    <div className='introReal'>
        <h1 id='titredepage'>Nos Réalisation :</h1>
        <p id='pghrIntro'>
        Chez HA SERVICES BÂTIMENT, nous sommes fiers de chaque projet que nous entreprenons. Spécialisés en plâtrerie, menuiserie, peinture, carrelage et ravalement de façade, nous mettons notre expertise au service de vos idées pour donner vie à des espaces fonctionnels, esthétiques et durables. Chaque réalisation témoigne de notre engagement envers la qualité, le savoir-faire et la satisfaction de nos clients. Découvrez ci-dessous quelques exemples de projets que nous avons eu le plaisir de mener à bien.

Pour en voir davantage, n'hésitez pas à consulter notre page <a href='https://www.facebook.com/ram16platrerie16'>Facebook</a>  où vous trouverez encore plus de nos réalisations et des projets en cours.
        </p>
    </div>
  )
}

export default RealisationIntrod