import React from 'react'
import './RealisationThree.css'
import Carousel from 'react-bootstrap/Carousel';

function RealisationThree
() {
    return (
        <Carousel id='idCarouselOneRealistaion'>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant31.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>1 er etape apres la pose des bandes.
              Préparations et passages des bandes sur placo plâtre.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant32.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>1 er etape apres la pose des bandes.
              Préparations et passages des bandes sur placo plâtre.</p>          </Carousel.Caption>
        </Carousel.Item>
    
        
    
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/avant33.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>1 er etape apres la pose des bandes.
              Préparations et passages des bandes sur placo plâtre.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr31.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>2ème étape camouflalage et protection. 
              1 Couche d impression 
              2 révision des bandes 
              3 finissent (en cours).
            </p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr32.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>2ème étape camouflalage et protection. 
              1 Couche d impression 
              2 révision des bandes 
              3 finissent (en cours).
            </p>          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr33.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>2ème étape camouflalage et protection. 
              1 Couche d impression 
              2 révision des bandes 
              3 finissent (en cours).
            </p>          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/entr34.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>2ème étape camouflalage et protection. 
              1 Couche d impression 
              2 révision des bandes 
              3 finissent (en cours).
            </p>          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr31.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr32.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
    
        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr33.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
       

        <Carousel.Item id='blocPhotoThree'>
          <img
            id='imgOneRealtisaion'
            className="d-block w-100"
            src="/apr34.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 id='titredeRealisationPhoto'>Realisation HA</h5>
            <p id='pghrPhoto'>Grâce à l'expertise de HA SERVICES BÂTIMENT, cet espace a été entièrement transformé. Plâtrerie impeccable, menuiserie sur mesure, peinture parfaite, carrelage soigné et façade rénovée. Un nouveau look élégant et durable pour ce bâtiment.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      )
}

export default RealisationThree
