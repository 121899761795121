import React from 'react';
import './AcceuilPageOne.css';

function AcceuilPageOne() {
  return (
    <div className='PermierdivAcceuil'>
      <div className='divAcceuil'>
        <div className='roll-container'>
          <h1 id='titreDuAcceuil'>
            HA Services Batiment
          </h1>
        </div>
        <p id='pargrapheAcceuil'>
          <p id='pargrapheAcceuil'> <span id='nomdentreprise'>HA Services Bâtiment</span> est une entreprise spécialisée dans les travaux de plâtrerie, menuiserie, peinture, carrelage et ravalement de façade. Forts d'une expérience solide dans le secteur du bâtiment, nous offrons des services de haute qualité, adaptés aux besoins de chaque client. Que ce soit pour une rénovation, une construction neuve ou des travaux d’embellissement, notre équipe de professionnels qualifiés est à votre écoute pour transformer vos projets en réalité. Nous mettons un point d'honneur à respecter les délais, tout en garantissant une finition impeccable et durable. Chez <span id='nomdentreprise'>HA Services Bâtiment</span>, votre satisfaction est notre priorité. Nous utilisons des matériaux de qualité pour des résultats à la hauteur de vos attentes, qu’il s’agisse d’une petite retouche ou d’un grand chantier. Faites confiance à notre expertise pour redonner vie à vos espaces et valoriser votre patrimoine. Nous sommes là pour vous accompagner dans tous vos travaux avec professionnalisme et rigueur, en respectant vos exigences et votre budget.</p>
        </p>
      </div>
      <img src='/LogoHA.png' alt='Logo' id='logoAcceuil' />
    </div>
  );
}

export default AcceuilPageOne;
