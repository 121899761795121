import { Route, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./Component/NavBar/NavBar";
import Acceuil from "./Component/Acceuil/Acceuil";
import Contact from "./Component/Contact/Contact";
import Realisation from "./Component/Realisation/Realisation";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Acceuil />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/nos-realisations" element={<Realisation />} />
      </Routes>
    </div>
  );
}

export default App;
