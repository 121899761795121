import React from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
function NavBar() {
  return (
    <div className="navbarHA">
      <Link to="/">
        <img src="/LogoHANav.png" id="navbarImage" />
      </Link>
      <div className="contenuNav">
        <Link to="/" className="contenuNavBarHA">
          {" "}
          <h4>Acceuil</h4>
        </Link>
        <Link to="/nos-realisations" className="contenuNavBarHA">
          {" "}
          <h4>Nos Réalisations</h4>
        </Link>
        <Link to="/contact" className="contenuNavBarHA">
          {" "}
          <h4>Contact</h4>
        </Link>
      </div>
    </div>
  );
}

export default NavBar;
