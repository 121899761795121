import React from 'react'
import './Realisation.css'
import RealisationOne from './RealisationOne/RealisationOne'
import RealisationIntrod from './RealisationIntrod/RealisationIntrod'
import RealisationTwo from './RealisationTwo/RealisationTwo'
import RealisationThree from './RealisationThree/RealisationThree'
import RealisationFour from './RealisationFour/RealisationFour'
import RealisationFive from './RealisationFive/RealisationFive'
import RealisationVideo from './RealisationVideo/RealisationVideo'
import RealisationVidContenu from './RealistionVidContenu/RealisationVidContenu'

function Realisation() {
  return (
    <div  className="realisation">
      <RealisationIntrod/>
      <RealisationOne/>
      <RealisationTwo/>
      <RealisationThree/>
      <RealisationFour/>
      <RealisationFive/>
      <RealisationVideo/>
      <RealisationVidContenu/>
    </div>
  )
}

export default Realisation